
import { getPublic } from '@/api/public.js'

function getStatis() {
  var statis = {}
  getPublic().then(res => {
    // 三方统计
    statis = res.result.top.statis
    // var bd_script = document.createElement('script')
    // bd_script.type = 'text/javascript'
    // bd_script.innerHTML = statis.baidu
    // // console.log(statis, 'statis')
    // document.body.appendChild(bd_script)
    var cc_script = document.createElement('script')
    cc_script.type = 'text/javascript'
    cc_script.innerHTML = statis.circle
    document.body.appendChild(cc_script)
    var cz_script = document.createElement('script')
    cz_script.type = 'text/javascript'
    cz_script.src = 'https://s4.cnzz.com/z_stat.php?id=5078536&web_id=5078536'
    cz_script.innerHTML = statis.cnzz
    document.body.appendChild(cz_script)
  })
}

export default getStatis

