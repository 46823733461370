<template>
  <div>
    <indexsquare
      :title="title"
      :category="classl"
      :category-show="true"
      :zlyc="zlyc"
      :type="type"
      @listWithCategory="listWithCategory"
      @sendMsg="sendMsg"
      @is_div_show="is_div_show"
    ></indexsquare>
    <indexsquare
      :title="yxtitle"
      :category="classl"
      :category-show="false"
      :zlyc="jdyx"
      :type="type2"
      @sendMsg="sendMsg"
      @is_div_show="is_div_show"
    ></indexsquare>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import indexsquare from '@/components/indexcom/index'
import { classlist, list } from '@/api/indexapi'
import getStatis from '@/utils/statisSeo.js'
export default {
  name: 'Indexcom',
  components: {
    indexsquare
  },
  data() {
    return {
      classl: [
        { name: '全部', id: 0, select: true }
      ],
      title: '筑龙原创',
      yxtitle: '京东优选',
      zlyc: [],
      jdyx: [],
      type: '1',
      type2: '2',
      category_id: '',
      index_search_text: '',
      queryId: ''
    }
  },
  mounted() {
    // 三方统计
    if (window.location.origin == 'https://www.zk468.com') {
      getStatis()
    }
    var banner = document.getElementById('banner')
    banner.style.display = 'block'
    this.classlistM()
    this.getlist()
  },
  methods: {
    is_div_show(val) {
      this.queryId = val
    },
    classlistM() {
      classlist().then((res) => {
        // this.classl = res.result
        var arr = res.result
        arr.forEach(e => {
          e.select = false
          this.classl.push(e)
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    listWithCategory(id) {
      this.category_id = id
      this.getlist()
    },
    //   筑龙原创1,京东优选2
    getlist() {
      Cookies.set('index_type', '')
      var info = {
        page: 1,
        limit: 16,
        category_id: this.category_id
      }
      list(info).then((res) => {
        this.zlyc = res.result.zlProduct
        if (this.queryId) {
          this.zlyc.push({
            listType: 1
          })
        }

        this.jdyx = res.result.jdProduct

        if (this.queryId) {
          this.jdyx.push({
            listType: 1
          })
        }
      })
        .catch((err) => {
          console.log(err)
        })
    },
    sendMsg(ids) {
      this.$router.push({
        path: '/gift/more',
        query: { id: ids }
      })
    }
  }
}
</script>
